import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  background: var(--border);
  border: .1rem solid rgba(0,0,0,.05);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  a {
    text-decoration: none;
  }
  .logo  {
    align-items: center;
    display: flex;
    height: 30rem;
    justify-content: center;
    width: 30rem;
    img {
      height: auto;
      transition: all .2s ease-in-out;
      width: 55%;
    }
  }
  .name {
    background: var(--primary);
    color: var(--header-text);
    font-weight: bold;
    padding: 1.1rem 0;
    text-align: center;
    text-transform: uppercase;
  }
  &:hover {
    .logo {
      img {
        width: 80%;
      }
    }
  }
`;